<template>
	<div class="sms-code-input__wrapper">
		<div class="sms-code-inputs">
			<input
				:class="['sms-code-input', error && 'sms-code-input__error']"
				v-for="index in 4"
				:key="index"
				v-maska="'#'"
				type="tel"
				pattern="^[0-9]$"
				v-model="code[index - 1]"
				maxlength="1"
				:ref="`input-${index - 1}`"
				@input="moveFocus(index - 1, $event)"
				@keydown="handleBackward(index - 1, $event)"
				@focus="$emit('clearError')"
			/>
		</div>
		<span class="sms-code-input__error-text">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "SmsCodeInput",
	data()
	{
		return {
			code: []
		};
	},
	props: {
		error: {
			type: String,
			default: ""
		}
	},
	methods: {
		inputCode(e) {
			const value 			= e.data?.split('')
			const inputFields = document.querySelectorAll('.sms-code-input')

			if (value && value.length > 1 && value.length < 5) {
				this.code = value
				const code = this.code.join('');

				if (value.length == 4)
				{	
					inputFields[3].focus()
					this.$emit('handleCodeEntered', code);
				} else
					inputFields[code.length].focus()

				return true
			} else return false
		},
		moveFocus(index, e)
		{
			const isCode = this.inputCode(e)
			if (isCode) return

			const value = e.target.value;

			if (!value.trim() || !/^\d+$/.test(value)) return;

			if (value && index < 3)
				this.$refs[`input-${index + 1}`][0].focus();

			const code = this.code.join('');

			if (code.length === 4) this.$emit('handleCodeEntered', code);
		},
		handleBackward(index, e)
		{
			if (e.key === 'Backspace' && !e.target.value && index > 0)
			{
				this.$refs[`input-${index - 1}`][0].focus();
			}
		},
		async handleInsertCode()
		{
			if (!('OTPCredential' in window)) return;

			try
			{
				const otp = await navigator.credentials.get({
					otp: {transport: ['sms']}
				});

				if (!otp) return;

				this.code = otp.code;
				this.$emit('handleCodeEntered', otp.code);
			} catch (e)
			{
				throw new Error(e);
			}
		}
	},
	mounted()
	{
		this.handleInsertCode();
	}
};
</script>

<style lang="scss">
.sms-code-input__wrapper
{
	display: flex;
	flex-direction: column;
}

.sms-code-inputs
{
	display: flex;
	align-items: center;
	gap: 16px;
}

.sms-code-input
{
	text-align: center;
	border: 1px solid $greyDelimiter;

	background-color: $greyBackground;
	box-sizing: border-box;
	border-radius: 8px;

	min-height: 56px;
	max-width: 48px;
	padding: 0 12px;

	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 32px;
	line-height: 125%;

	&:focus-within
	{border-color: $green;}
}

.sms-code-input.sms-code-input__error
{border-color: $red;}

.sms-code-input__error-text
{
	font-size: 12px;
	font-family: $mainFontBoldNew;
	color: $red;
}
</style>